import axios from '@axios'
import themeConfig from '@themeConfig'

export default {
  namespaced: true,
  state: {
    calendarSports: [],
    selectedSports: [],
  },
  getters: {},
  mutations: {
    SET_SELECTED_CALENDARS(state, val) {
      state.selectedSports = val
    },
    SET_SPORTS(state, val) {
      state.calendarSports = val
    },
  },
  actions: {
    fetchEvents( {store}, interval) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/workouts', {
              params: {
                start: interval.start,
                end: interval.end,
                sports: interval.sports
              }
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSports({ state }) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/sports')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStats({ store }, params) {
      return new Promise((resolve, reject) => {
        axios
          .get(themeConfig.api_prefix + '/stats',{params})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + '/workouts', { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    updateEvent(ctx, { event }) {
      return new Promise((resolve, reject) => {
        axios
          .post(themeConfig.api_prefix + `/events/${event.id}`, { event })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    removeEvent(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(themeConfig.api_prefix + `/workouts/${id}/delete`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
