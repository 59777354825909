<template>
  <v-card id="customers-list">
    <!-- search -->
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">

        <v-btn
          color="primary"
          class="me-3"
          @click="addSport"
        >
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>New sport</span>
        </v-btn>
      </div>
    </v-card-text>

    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-uppercase">
              #
            </th>
            <th class="text-uppercase">
              Name
            </th>
            <th class="text-uppercase">
              Slug
            </th>
            <th class="text-center text-uppercase">
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(sport,sportKey) in sports"
            :key="sportKey"
          >
            <td>{{ sport.sport_id }}</td>
            <td class="">
              {{ sport.name }}
            </td>
            <td class="">
              {{ sport.slug }}
            </td>
            <td class="text-center">
              <v-icon
                small
                class=""
                @click="editSport(sport)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
              &nbsp;
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <sport-form
        :sport="sportData"
        @close-dialog="dialog = false"
        @refresh-list="fetchSports"
      ></sport-form>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiDownloadOutline,
  mdiPencilOutline,
  mdiContentCopy,
  mdiMagnify
} from '@mdi/js'

import { onUnmounted, ref, watch } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import store from '@/store'
import SportForm from './SportForm.vue'

// store module
import calendarStoreModule from '../calendar/calendarStoreModule'

export default {
  components: {
    SportForm
  },
  setup() {

    const CALENDAR_APP_STORE_MODULE_NAME = 'app-calendar'

    // Register module
    if (!store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) {
      store.registerModule(CALENDAR_APP_STORE_MODULE_NAME, calendarStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CALENDAR_APP_STORE_MODULE_NAME)) store.unregisterModule(CALENDAR_APP_STORE_MODULE_NAME)
    })

    const sports = ref([])
    const dialog = ref(false)
    const sportData = ref({})

    const fetchSports = () => {
      store.dispatch('app-calendar/fetchSports', {

      }).then(response => {
        sports.value = response.data
      }).catch(error => {
        console.log(error)
      })
    }

    fetchSports();

    const addSport = () => {
      sportData.value = {}
      dialog.value = true
    }

    const editSport = (item) => {
      sportData.value = item
      dialog.value = true
    }

    return {
      sports,
      dialog,
      sportData,
      editSport,
      addSport,
      fetchSports,
      icons: {
        mdiTrendingUp,
        mdiPlus,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiEyeOutline,
        mdiContentCopy,
        mdiDownloadOutline,
        mdiPencilOutline,
        mdiMagnify
      },
    }
  },
}
</script>

<style lang="scss" scoped>

</style>
