<template>
  <v-card
    flat
  >
    <v-card-title>
      <span class="headline">{{ optionsLocal.sport_id ? 'Edit' : 'Add' }} sport</span>
    </v-card-title>
    <v-form ref="sportForm">

      <v-card-text class="">
            <v-text-field
              v-model="optionsLocal.name"
              label="Name"
              :rules="[validators.required]"
            ></v-text-field>

            <v-text-field
              v-model="optionsLocal.slug"
              label="Slug"
              :rules="[validators.required]"
              v-if="optionsLocal.sport_id"
            ></v-text-field>

      </v-card-text>

      <v-card-text class="d-flex mt-5">
        <v-btn
          color="success"
          class=""
          @click.prevent="formSubmit"
        >
          Save changes
        </v-btn>
        <v-btn
          outlined
          class="ml-auto"
          color="secondary"
          type="reset"
          @click.prevent="resetForm"
        >
          Cancel
        </v-btn>
      </v-card-text>
    </v-form>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { required } from '@core/utils/validation'
import axios from '@axios'
import store from '@/store'
import themeConfig from '@themeConfig'

export default {
  props: {
    sport: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, {emit}) {
    const optionsLocal = ref(JSON.parse(JSON.stringify(props.sport)))

    const resetForm = () => {
      optionsLocal.value = JSON.parse(JSON.stringify(props.sport))
      emit('close-dialog')
    }

    watch(
      () => props.sport,
      () => {
        optionsLocal.value = JSON.parse(JSON.stringify(props.sport))
      },
      { deep: true },
    )

    const sportForm = ref(null)

    const formSubmit = () => {
      const isFormValid = sportForm.value.validate()

      if (!isFormValid) return

      if (optionsLocal.value.sport_id) {
        axios
          .post(themeConfig.api_prefix + '/sports/'+optionsLocal.value.sport_id+"/edit", optionsLocal.value)
          .then(response => {
            store.dispatch("addNotification",{message:"Sport info were updated.",type: "success"});
            emit('close-dialog')
            emit('refresh-list')
          })
          .catch(error => {
            console.log(error)
            // errorMessages.value = error.response.data.error
          })
      } else {
        axios
          .post(themeConfig.api_prefix + '/sports', optionsLocal.value)
          .then(response => {
            if (response.data.success) {
              store.dispatch("addNotification",{message:"Sport was inserted.",type: "success"});
            } else {
              store.dispatch("addNotification",{message:"Something went wrong.",type: "error"});
            }
            emit('close-dialog')
            emit('refresh-list')
          })
          .catch(error => {
            console.log(error)
            // errorMessages.value = error.response.data.error
          })
      }



    }

    return {
      optionsLocal,
      resetForm,
      sportForm,
      formSubmit,
      validators: {
        required
      },
    }
  }
}
</script>
